<template>
  <div id="transferWindowClean">
    <el-dialog
      :title="transferWindowCleanFormTitle"
      width="1200px"
      :visible.sync="transferWindowCleanDialogVisible"
      :close-on-click-modal="false"
      @close="transferWindowCleanDialogClose"
    >
      <el-form
        ref="transferWindowCleanFormRef"
        :model="transferWindowCleanForm"
        :rules="transferWindowCleanFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="设备编号" prop="deviceNo">
              <el-input v-model="transferWindowCleanForm.deviceNo" placeholder="请输入设备编号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="安装位置" prop="location">
              <el-input v-model="transferWindowCleanForm.location" placeholder="请输入安装位置" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="使用时间" prop="usageTime">
              <el-date-picker v-model="transferWindowCleanForm.usageTime" placeholder="请选择日期" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="清洁情况" prop="cleanliness">
              <el-select v-model="transferWindowCleanForm.cleanliness" placeholder="请输入清洁情况">
                 <el-option key="1" label="已清洁" :value="1" />
                 <el-option key="2" label="未清洁" :value="2" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="设备状态" prop="deviceStatus">
              <el-select v-model="transferWindowCleanForm.deviceStatus" placeholder="请输入设备状态">
                 <el-option key="1" label="完好" :value="1" />
                 <el-option key="2" label="故障" :value="2" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="使用时间（h）" prop="timeCost">
              <el-input v-model="transferWindowCleanForm.timeCost" placeholder="请输入使用时间（h）" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="记录人" prop="recorder">
              <el-input v-model="transferWindowCleanForm.recorder" placeholder="请输入记录人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="transferWindowCleanForm.remarks"
                placeholder="请输入备注"
                type="textarea"
                :rows="5"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="transferWindowCleanDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="transferWindowCleanFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="设备编号">
        <el-input v-model="searchForm.deviceNo" placeholder="请输入设备编号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="transferWindowCleanPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="deviceNo" label="设备编号" />
      <el-table-column prop="location" label="安装位置" />
      <el-table-column prop="usageTime" label="使用时间" />
      <el-table-column prop="cleanliness" label="清洁情况" >
          <template slot-scope="scope">
          <span v-if="scope.row.cleanliness == 1">已清洁</span>
          <span v-if="scope.row.cleanliness == 2">未清洁</span>
        </template>
      </el-table-column>
      <el-table-column prop="deviceStatus" label="设备状态" >
          <template slot-scope="scope">
          <span v-if="scope.row.deviceStatus == 1">完好</span>
          <span v-if="scope.row.deviceStatus == 2">故障</span>
        </template>
      </el-table-column>
      <el-table-column prop="deviceStatus" label="使用时间（h）" />
      <el-table-column prop="recorder" label="记录人" />
      <el-table-column prop="remarks" label="备 注" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="transferWindowCleanPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addTransferWindowClean, deleteTransferWindowClean, updateTransferWindowClean, selectTransferWindowCleanInfo, selectTransferWindowCleanList } from '@/api/universal/transferWindowClean'

export default {
  data () {
    return {
      transferWindowCleanDialogVisible: false,
      transferWindowCleanFormTitle: '',
      transferWindowCleanForm: {
        id: '',
        deviceNo: '',
        location: '',
        usageTime: '',
        cleanliness: '',
        deviceStatus: '',
        timeCost: '',
        recorder: '',
        remarks: ''
      },
      transferWindowCleanFormRules: {
        deviceNo: [{ required: true, message: '设备编号不能为空', trigger: ['blur', 'change']}]
      },
      transferWindowCleanPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        deviceNo: ''
      }
    }
  },
  created () {
    selectTransferWindowCleanList(this.searchForm).then(res => {
      this.transferWindowCleanPage = res
    })
  },
  methods: {
    transferWindowCleanDialogClose () {
      this.$refs.transferWindowCleanFormRef.resetFields()
    },
    transferWindowCleanFormSubmit () {
      if (this.transferWindowCleanFormTitle === '传递窗清洁灭菌记录') {
        this.transferWindowCleanDialogVisible = false
        return
      }
      this.$refs.transferWindowCleanFormRef.validate(async valid => {
        if (valid) {
          if (this.transferWindowCleanFormTitle === '新增传递窗清洁灭菌记录') {
            await addTransferWindowClean(this.transferWindowCleanForm)
          } else if (this.transferWindowCleanFormTitle === '修改传递窗清洁灭菌记录') {
            await updateTransferWindowClean(this.transferWindowCleanForm)
          }
          this.transferWindowCleanPage = await selectTransferWindowCleanList(this.searchForm)
          this.transferWindowCleanDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.transferWindowCleanFormTitle = '新增传递窗清洁灭菌记录'
      this.transferWindowCleanDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteTransferWindowClean(row.id)
        if (this.transferWindowCleanPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.transferWindowCleanPage = await selectTransferWindowCleanList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.transferWindowCleanFormTitle = '修改传递窗清洁灭菌记录'
      this.transferWindowCleanDialogVisible = true
      this.selectTransferWindowCleanInfoById(row.id)
    },
    handleInfo (index, row) {
      this.transferWindowCleanFormTitle = '传递窗清洁灭菌记录详情'
      this.transferWindowCleanDialogVisible = true
      this.selectTransferWindowCleanInfoById(row.id)
    },
    selectTransferWindowCleanInfoById (id) {
      selectTransferWindowCleanInfo(id).then(res => {
        this.transferWindowCleanForm.id = res.id
        this.transferWindowCleanForm.deviceNo = res.deviceNo
        this.transferWindowCleanForm.location = res.location
        this.transferWindowCleanForm.usageTime = res.usageTime
        this.transferWindowCleanForm.cleanliness = res.cleanliness
        this.transferWindowCleanForm.deviceStatus = res.deviceStatus
        this.transferWindowCleanForm.timeCost = res.timeCost
        this.transferWindowCleanForm.recorder = res.recorder
         this.transferWindowCleanForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectTransferWindowCleanList(this.searchForm).then(res => {
        this.transferWindowCleanPage= res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectTransferWindowCleanList(this.searchForm).then(res => {
        this.transferWindowCleanPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectTransferWindowCleanList(this.searchForm).then(res => {
        this.transferWindowCleanPage = res
      })
    }
  }
}
</script>

<style>
</style>
